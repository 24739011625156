import InstagramIcon from '@material-ui/icons/Instagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faMicrophone,
  faVideo,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faArrowRight,
  faStarHalfAlt,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookRoundedIcon from '@material-ui/icons/FacebookRounded';
import LinkedinIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CancelIcon from './cancel-icon.svg';
import CheckCircleOutlineIcon from './check-outline.svg';
import AcceleranceIcon from './accelerance.svg';
import ClutchBadgePHIcon from './clutch_ph.svg';
import ClutchBadgeSofiaIcon from './clutch_sofia.svg';
import ClutchRatingIcon from './clutch_rating.svg';
import ArrowRight from './arrow-right.svg';
import Logo from './logo.svg';
import ArcFooterLogo from './arc-footer-logo.svg';
import BurgerIcon from './burger.svg';
import MinusIcon from './minus.svg';
import GearIcon from './gear-icon.svg';
import EmployeeIcon from './employee-icon.svg';
import MapPinIcon from './map-pin-icon.svg';
import MapPinSolidIcon from './map-pin-solid-icon.svg';
import ChatIcon from './chat.svg';
import ArrowDownSecondaryIcon from './arrow-down-secondary.svg';
import ArrowRightRounded from './arrow-right-rounded.svg';
import QuestionTooltip from './questionTooltip.svg';
import ArrowRightTopIcon from './arrow-right-top.svg';
import FacebookRoundIcon from './facebook-round.svg';
import CompassIcon from './compass-icon.svg';
import BoldChevronRight from './bold-chevron-right.svg';
import CookieIcon from './cookie-icon.svg';
import ClipIcon from './clip.svg';
import ReviewStar from './ReviewStar.svg';
import EmptyReviewStar from './EmptyReviewStar.svg';
import Quote from './quote.svg';
import QuoteRounded from './quote-rounded.svg';
import ElfLogo from './elf-logo.svg';
import VenturesLogo from './ventures-logo';
import FileIcon from './file.svg';
import BestWorkPlacesIcon from './bestworkplaces.svg';
import GreatPlaceToWorkIcon from './great-place-to-work.svg';
import ArrowRightV2 from './arrow-right-v2.svg';
// Import all arcanys icon family
import OneUpIcon from './arcanys-icon-family/1up.svg';
import BulbIcon from './arcanys-icon-family/Bulb.svg';
import ChartIcon from './arcanys-icon-family/Chart.svg';
import ClipboardIcon from './arcanys-icon-family/Clipboard.svg';
import CoinIcon from './arcanys-icon-family/Coin.svg';
import GameIcon from './arcanys-icon-family/Game.svg';
import GemIcon from './arcanys-icon-family/Gem.svg';
import HandshakeIcon from './arcanys-icon-family/Handshake.svg';
import HeartIcon from './arcanys-icon-family/Heart.svg';
import PersonArrowUpIcon from './arcanys-icon-family/PersonArrowUp.svg';
import PuzzleIcon from './arcanys-icon-family/Puzzle.svg';
import SmileMaleIcon from './arcanys-icon-family/SmileMale.svg';
import SpeechBubbleIcon from './arcanys-icon-family/SpeechBubble.svg';
import StarIcon from './arcanys-icon-family/Star.svg';
import StopwatchIcon from './arcanys-icon-family/Stopwatch.svg';
import TargetIcon from './arcanys-icon-family/Target.svg';
import TrophyIcon from './arcanys-icon-family/Trophy.svg';
// Social Media Icons
import InstagramIconInhouse from './social-media-icons/instagram.svg';
import YouTubeIconInhouse from './social-media-icons/youtube.svg';
import FacebookIconInhouse from './social-media-icons/facebook.svg';
import LinkedinIconInhouse from './social-media-icons/linkedin.svg';

const ChevronUp: React.FC = () => <FontAwesomeIcon icon={faChevronUp} />;
const ChevronRight: React.FC = (props) => (
  <FontAwesomeIcon {...props} icon={faChevronRight} />
);
const ChevronLeft: React.FC = (props) => (
  <FontAwesomeIcon {...props} icon={faChevronLeft} />
);
const MicrophoneIcon: React.FC = () => <FontAwesomeIcon icon={faMicrophone} />;
const VideoIcon: React.FC = () => <FontAwesomeIcon icon={faVideo} />;
const ChevronDown: React.FC = (props) => (
  <FontAwesomeIcon {...props} icon={faChevronDown} />
);
const ArrowRightSolidIcon: React.FC = () => (
  <FontAwesomeIcon icon={faArrowRight} />
);
const Star: React.FC = () => <FontAwesomeIcon icon={faStar} />;
const StarHalf: React.FC = () => <FontAwesomeIcon icon={faStarHalfAlt} />;
const StarEmpty: React.FC = () => <FontAwesomeIcon icon={farStar} />;

const arcanysIcons = {
  '1 Up': <OneUpIcon />,
  Bulb: <BulbIcon />,
  Chart: <ChartIcon />,
  Clipboard: <ClipboardIcon />,
  Coin: <CoinIcon />,
  Game: <GameIcon />,
  Gem: <GemIcon />,
  Handshake: <HandshakeIcon />,
  Heart: <HeartIcon />,
  'Person Arrow Up': <PersonArrowUpIcon />,
  Puzzle: <PuzzleIcon />,
  'Smile Male': <SmileMaleIcon />,
  'Speech Bubble': <SpeechBubbleIcon />,
  Star: <StarIcon />,
  Stopwatch: <StopwatchIcon />,
  Target: <TargetIcon />,
  Trophy: <TrophyIcon />,
};

const socialMediaIcons = {
  Instagram: <InstagramIconInhouse />,
  YouTube: <YouTubeIconInhouse />,
  Facebook: <FacebookIconInhouse />,
  Linkedin: <LinkedinIconInhouse />,
};
export {
  QuestionTooltip,
  EmptyReviewStar,
  ChevronLeft,
  CheckCircleOutlineIcon,
  ArrowRight,
  ArrowRightV2,
  ReviewStar,
  InstagramIcon,
  MicrophoneIcon,
  Logo,
  ArcFooterLogo,
  BurgerIcon,
  MinusIcon,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  GearIcon,
  EmployeeIcon,
  MapPinIcon,
  MapPinSolidIcon,
  ArrowRightRounded,
  LinkedinIcon,
  FacebookIcon,
  FacebookRoundIcon,
  FacebookRoundedIcon,
  TwitterIcon,
  YoutubeIcon,
  AcceleranceIcon,
  ClutchBadgePHIcon,
  ClutchBadgeSofiaIcon,
  ClutchRatingIcon,
  ArrowRightSolidIcon,
  VideoIcon,
  ArrowRightTopIcon,
  ChatIcon,
  ArrowDownSecondaryIcon,
  CompassIcon,
  BoldChevronRight,
  CookieIcon,
  ClipIcon,
  CancelIcon,
  Quote,
  QuoteRounded,
  ElfLogo,
  VenturesLogo,
  FileIcon,
  Star,
  StarHalf,
  StarEmpty,
  GreatPlaceToWorkIcon,
  BestWorkPlacesIcon,
  arcanysIcons,
  socialMediaIcons,
};
