import React from 'react';

interface VenturesLogoProps {
  id: string;
}
const VenturesLogo: React.FC<VenturesLogoProps> = ({ id }) => (
  <svg viewBox="0 0 356 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M65.4393 2.71753L55.4877 28.3527H59.9123L62.2512 22.2718H73.0817L75.4206 28.3527H79.8451L69.8936 2.71753H65.4393ZM63.8303 18.2477L67.6739 8.24698L71.5174 18.2477H63.8303Z"
      fill="#122636"
    />
    <path
      d="M86.3702 13.5231V10.9894H82.1989V28.3527H86.5937V16.9957C86.5937 16.9957 87.8004 14.6558 90.4819 14.6558C91.7631 14.6558 92.5676 15.0582 92.5676 15.0582L94.102 11.1086C94.102 11.1086 92.6719 10.5571 91.0927 10.5571C87.8749 10.5571 86.3702 13.5231 86.3702 13.5231Z"
      fill="#122636"
    />
    <path
      d="M104.456 24.6863C101.491 24.6863 99.5843 22.3761 99.5843 19.6636C99.5843 16.951 101.491 14.6558 104.456 14.6558C107.093 14.6558 108.523 16.489 108.523 16.489L111.562 13.7764C111.562 13.7764 109.104 10.5571 104.456 10.5571C99.2565 10.5571 95.1151 14.5514 95.1151 19.6785C95.1151 24.7757 99.2565 28.7999 104.456 28.7999C109.148 28.7999 111.636 25.506 111.636 25.506L108.746 22.5699C108.746 22.5699 107.42 24.6863 104.456 24.6863Z"
      fill="#122636"
    />
    <path
      d="M126.936 13.2548C126.936 13.2548 125.327 10.5422 121.707 10.5422C117.208 10.5422 113.245 14.313 113.245 19.6636C113.245 25.0142 117.193 28.785 121.707 28.785C125.446 28.785 126.936 26.0724 126.936 26.0724V28.3378H131.003V10.9894H126.936V13.2548ZM126.608 22.2718C126.608 22.2718 125.327 24.6863 122.512 24.6863C119.547 24.6863 117.715 22.5252 117.715 19.6636C117.715 16.802 119.547 14.6409 122.512 14.6409C125.327 14.6409 126.608 17.0554 126.608 17.0554V22.2718Z"
      fill="#122636"
    />
    <path
      d="M144.694 10.5571C141.029 10.5571 139.495 13.5231 139.495 13.5231V10.9894H135.398V28.3527H139.793V16.8914C139.793 16.8914 140.97 14.6558 143.234 14.6558C145.141 14.6558 146.675 15.714 146.675 18.203V28.3527H151.07V17.115C151.055 12.9269 148.061 10.5571 144.694 10.5571Z"
      fill="#122636"
    />
    <path
      d="M164.135 23.7025L158.28 10.9893H153.409L161.871 28.755L161.171 30.5435C160.336 32.6599 159.711 33.1071 158.206 33.1071C157.074 33.1071 155.897 31.9743 155.897 31.9743L153.841 35.3874C153.841 35.3874 155.852 37.2206 158.489 37.2206C161.051 37.2206 163.509 36.0432 164.999 32.2426L173.416 11.0042H168.694L164.135 23.7025Z"
      fill="#122636"
    />
    <path
      d="M183.83 17.9794C180.895 17.3534 179.733 17.1001 179.733 16.0419C179.733 14.9837 181.238 14.3875 182.876 14.3875C185.215 14.3875 187.196 15.8928 187.196 15.8928L189.386 12.6735C189.386 12.6735 186.779 10.5422 182.876 10.5422C178.109 10.5422 175.413 13.2548 175.413 15.9674C175.413 19.2612 178.407 20.7218 181.848 21.467C184.485 22.0185 185.29 22.242 185.29 23.3747C185.29 24.4329 183.785 24.9546 181.997 24.9546C179.062 24.9546 176.768 22.4656 176.768 22.4656L174.31 25.6551C174.31 25.6551 177.051 28.7999 182.146 28.7999C186.571 28.7999 189.729 26.4152 189.729 23.4493C189.714 19.8574 187.271 18.7246 183.83 17.9794Z"
      fill="#122636"
    />
    <path
      d="M63.8452 75.6734C62.6385 75.6734 61.551 74.9729 61.0445 73.8848L31.4432 10.1248L19.5997 33.2411C19.0783 34.2695 18.0205 34.9104 16.8585 34.9104H3.10814C1.40982 34.9104 0.024353 33.5243 0.024353 31.8252C0.024353 30.1262 1.40982 28.7401 3.10814 28.7401H14.9814L28.851 1.68898C29.3873 0.64569 30.4599 -0.0399012 31.6517 0.0197155C32.8286 0.0495239 33.8864 0.735115 34.3929 1.80822L63.8899 65.3149L74.8247 42.0793C75.3312 41.0062 76.4187 40.3057 77.6105 40.3057L352.916 40.2908C354.615 40.2908 356 41.6769 356 43.376C356 45.0751 354.615 46.4612 352.916 46.4612H79.5472L66.6311 73.8997C66.1245 74.9729 65.037 75.6734 63.8452 75.6734Z"
      fill={`url(#${id})`}
    />
    <path
      d="M188.642 3.99902H193.26L199.815 19.8273L206.295 3.99902H210.705L199.681 29.515L188.642 3.99902Z"
      fill="#ED0A67"
    />
    <path
      d="M223.412 12.1814C224.44 12.6136 225.319 13.1948 226.094 13.9699C226.854 14.73 227.464 15.654 227.911 16.7122C228.358 17.7853 228.582 18.9628 228.582 20.2594V20.9301C228.582 21.2133 228.567 21.5114 228.552 21.8393H216.083C216.172 22.2417 216.351 22.6441 216.589 23.0316C216.828 23.434 217.17 23.7917 217.573 24.1047C217.99 24.4177 218.466 24.686 219.003 24.8946C219.539 25.1033 220.15 25.2076 220.835 25.2076C221.535 25.2076 222.31 25.0735 223.129 24.8201C223.949 24.5667 224.694 24.1345 225.349 23.5235L227.464 26.698C226.779 27.2644 225.87 27.8009 224.723 28.3226C223.576 28.8442 222.25 29.0976 220.776 29.0976C219.509 29.0976 218.332 28.8741 217.23 28.4269C216.142 27.9798 215.189 27.3538 214.37 26.5639C213.565 25.7591 212.925 24.8201 212.463 23.747C212.001 22.659 211.777 21.4965 211.777 20.2594C211.777 19.0671 211.986 17.9344 212.418 16.8762C212.835 15.818 213.416 14.8939 214.161 14.104C214.906 13.3141 215.785 12.6881 216.828 12.241C217.856 11.7789 218.973 11.5554 220.18 11.5554C221.312 11.5405 222.384 11.764 223.412 12.1814ZM224.053 17.6512C223.874 17.2488 223.621 16.8762 223.278 16.5334C222.936 16.1906 222.504 15.9223 221.997 15.6988C221.476 15.4901 220.865 15.3858 220.165 15.3858C219.479 15.3858 218.898 15.4901 218.407 15.6988C217.915 15.9074 217.498 16.1757 217.155 16.5036C216.813 16.8315 216.56 17.1892 216.381 17.5916C216.202 17.994 216.113 18.3815 216.083 18.7541H224.366C224.321 18.4262 224.217 18.0387 224.053 17.6512Z"
      fill="#ED0A67"
    />
    <path
      d="M242.973 11.9728C243.718 12.2559 244.358 12.6583 244.924 13.2098C245.49 13.7463 245.937 14.4021 246.265 15.1771C246.593 15.9522 246.757 16.8464 246.757 17.8599V28.6803H242.526V18.9181C242.526 17.7258 242.198 16.8464 241.557 16.3099C240.917 15.7733 240.172 15.505 239.323 15.505C238.429 15.505 237.714 15.7435 237.178 16.2055C236.641 16.6825 236.209 17.1594 235.911 17.6512V28.6803H231.68V11.9728H235.628V14.4021C235.822 14.104 236.06 13.7762 236.343 13.4334C236.641 13.0906 236.999 12.7776 237.416 12.4944C237.833 12.2112 238.31 11.9877 238.846 11.8088C239.382 11.63 239.978 11.5405 240.664 11.5405C241.468 11.5405 242.243 11.6896 242.973 11.9728Z"
      fill="#ED0A67"
    />
    <path
      d="M252.939 11.9726V6.99463H257.17V11.9726H262.205V15.7732H257.17V22.5099C257.17 23.0315 257.23 23.4489 257.364 23.7916C257.498 24.1344 257.662 24.4027 257.855 24.5965C258.049 24.7902 258.287 24.9393 258.541 25.0138C258.794 25.1032 259.062 25.133 259.315 25.133C259.673 25.133 260.001 25.0734 260.298 24.9542C260.611 24.8349 260.879 24.7008 261.103 24.5369L262.652 27.9946C262.161 28.3225 261.565 28.5908 260.85 28.7845C260.15 28.9783 259.42 29.0826 258.66 29.0826C257.84 29.0826 257.081 28.9485 256.366 28.6653C255.665 28.3821 255.055 27.9797 254.533 27.473C254.012 26.9513 253.61 26.3403 253.341 25.61C253.058 24.8796 252.924 24.0748 252.924 23.1806V15.7732H249.647V11.9726H252.939Z"
      fill="#ED0A67"
    />
    <path
      d="M269.49 28.6805C268.745 28.3973 268.105 27.98 267.539 27.4434C266.973 26.9069 266.526 26.2511 266.183 25.4761C265.84 24.7011 265.677 23.8068 265.677 22.7933V11.9729H269.907V21.6308C269.907 22.8828 270.19 23.777 270.757 24.3285C271.323 24.8799 272.082 25.1631 273.051 25.1631C273.945 25.1631 274.66 24.9544 275.196 24.552C275.732 24.1347 276.179 23.6578 276.537 23.1212V11.9729H280.768V28.6805H276.82V26.2511C276.611 26.579 276.343 26.9069 276.045 27.2497C275.732 27.5925 275.375 27.8906 274.958 28.1588C274.541 28.4271 274.064 28.6507 273.527 28.8295C272.991 29.0084 272.41 29.0978 271.784 29.0978C270.995 29.0978 270.235 28.9637 269.49 28.6805Z"
      fill="#ED0A67"
    />
    <path
      d="M294.95 11.6745C295.442 11.764 295.933 11.8981 296.425 12.077L294.95 15.8775C294.712 15.7583 294.429 15.6689 294.086 15.5943C293.743 15.5198 293.386 15.49 293.013 15.49C292.03 15.49 291.226 15.7285 290.615 16.2203C290.004 16.6973 289.527 17.204 289.2 17.7555V28.6802H284.969V11.9726H288.991V14.402C289.393 13.6717 289.974 13.0159 290.749 12.4347C291.524 11.8534 292.477 11.5553 293.609 11.5553C294.012 11.5404 294.458 11.5851 294.95 11.6745Z"
      fill="#ED0A67"
    />
    <path
      d="M309.147 12.1814C310.175 12.6136 311.054 13.1948 311.829 13.9699C312.589 14.73 313.2 15.654 313.646 16.7122C314.093 17.7853 314.317 18.9628 314.317 20.2594V20.9301C314.317 21.2133 314.302 21.5114 314.287 21.8393H301.818C301.907 22.2417 302.086 22.6441 302.324 23.0316C302.563 23.434 302.905 23.7917 303.308 24.1047C303.725 24.4177 304.201 24.686 304.738 24.8946C305.274 25.1033 305.885 25.2076 306.57 25.2076C307.27 25.2076 308.045 25.0735 308.864 24.8201C309.684 24.5667 310.429 24.1345 311.084 23.5235L313.2 26.698C312.514 27.2644 311.605 27.8009 310.458 28.3226C309.311 28.8442 307.985 29.0976 306.511 29.0976C305.244 29.0976 304.067 28.8741 302.965 28.4269C301.877 27.9798 300.924 27.3538 300.105 26.5639C299.3 25.7591 298.66 24.8201 298.198 23.747C297.736 22.659 297.512 21.4965 297.512 20.2594C297.512 19.0671 297.721 17.9344 298.153 16.8762C298.57 15.818 299.151 14.8939 299.896 14.104C300.641 13.3141 301.52 12.6881 302.563 12.241C303.591 11.7789 304.708 11.5554 305.915 11.5554C307.032 11.5405 308.119 11.764 309.147 12.1814ZM309.773 17.6512C309.594 17.2488 309.341 16.8762 308.998 16.5334C308.656 16.1906 308.224 15.9223 307.717 15.6988C307.196 15.4901 306.585 15.3858 305.885 15.3858C305.2 15.3858 304.619 15.4901 304.127 15.6988C303.635 15.9074 303.218 16.1757 302.876 16.5036C302.533 16.8315 302.28 17.1892 302.101 17.5916C301.922 17.994 301.833 18.3815 301.803 18.7541H310.086C310.056 18.4262 309.952 18.0387 309.773 17.6512Z"
      fill="#ED0A67"
    />
    <path
      d="M319.65 23.8064C319.993 24.0896 320.38 24.343 320.812 24.5815C321.244 24.8199 321.736 25.0137 322.257 25.1627C322.793 25.3118 323.374 25.3863 324 25.3863C324.745 25.3863 325.43 25.2521 326.026 24.9988C326.622 24.7454 326.92 24.3281 326.92 23.7617C326.92 23.4785 326.831 23.255 326.652 23.0761C326.473 22.8973 326.235 22.7482 325.952 22.6439C325.669 22.5396 325.311 22.4203 324.894 22.3458C324.477 22.2564 324.06 22.167 323.613 22.0477C322.838 21.8838 322.078 21.6602 321.334 21.392C320.589 21.1237 319.933 20.7809 319.337 20.3636C318.756 19.9463 318.28 19.4693 317.922 18.9179C317.564 18.3664 317.401 17.7106 317.401 16.9207C317.401 16.2202 317.564 15.5346 317.877 14.8937C318.19 14.2529 318.652 13.6716 319.263 13.1649C319.874 12.6581 320.618 12.2557 321.483 11.9725C322.347 11.6744 323.345 11.5254 324.477 11.5254C325.952 11.5254 327.233 11.749 328.306 12.1961C329.378 12.6432 330.227 13.1052 330.853 13.5673L328.738 16.6673C328.246 16.3245 327.635 15.9817 326.905 15.6837C326.175 15.3856 325.371 15.2216 324.507 15.2216C323.762 15.2216 323.077 15.3558 322.466 15.6389C321.855 15.9221 321.542 16.3245 321.542 16.8313C321.542 17.3082 321.855 17.651 322.466 17.8746C323.077 18.0981 324.09 18.3664 325.49 18.6645C326.22 18.8284 326.935 19.0222 327.62 19.2607C328.32 19.4991 328.916 19.7972 329.438 20.1698C329.959 20.5424 330.376 21.0044 330.689 21.5708C331.002 22.1223 331.166 22.793 331.166 23.5978C331.166 24.492 330.972 25.282 330.57 25.9526C330.168 26.6382 329.631 27.2046 328.961 27.6666C328.291 28.1286 327.516 28.4714 326.652 28.7099C325.788 28.9484 324.864 29.0527 323.911 29.0527C323.017 29.0527 322.183 28.9633 321.423 28.7844C320.663 28.6056 319.963 28.382 319.322 28.0988C318.682 27.8157 318.116 27.4878 317.609 27.1301C317.103 26.7724 316.671 26.3998 316.328 26.0123L318.682 22.942C319.01 23.2699 319.307 23.5382 319.65 23.8064Z"
      fill="#ED0A67"
    />
    <defs>
      <linearGradient
        id={id}
        x1="0.0224164"
        y1="37.837"
        x2="355.979"
        y2="37.837"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#122636" />
        <stop offset="0.5737" stopColor="#ED0A67" />
        <stop offset="0.9387" stopColor="#ED0A67" stopOpacity="0.0962" />
        <stop offset="0.9776" stopColor="#ED0A67" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default VenturesLogo;
